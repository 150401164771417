@import "../../../styles/abstracts";

.lostContainer {
  position: relative;
}

.lost {
  @include appGrid;
  padding: 150px 0;
  text-align: center;

  @media screen and (min-width: #{$screen-large}) {
    padding: 175px 0 235px;
  }
}

.lostMessageContainer {
  grid-column-start: 1;
  grid-column-end: 13;
  margin-bottom: 40px;
}

.lostMessage {
  max-width: 210px;
  margin: auto;
}

.aboutLink {
  grid-column-start: 1;
  grid-column-end: 13;

  a {
    color: var(--white);
    opacity: 1;
    transition: opacity 0.35s var(--primary-curve);

    &:hover {
      opacity: 0.8;
    }
  }
}

.goHome {
  & > span:after {
    opacity: 0;
  }
}
